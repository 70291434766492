import { useEffect } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useConfirmEmail } from "../hooks/useConfirmEmail";
import Loader from "../shared/loader/Loader";

function ConfirmEmail() {
  const [searchParams] = useSearchParams();
  const token = encodeURIComponent(searchParams.get("token"));
  const email = searchParams.get("email");

  const navigate = useNavigate();

  const { error, confirmEmail, isPending, isError } = useConfirmEmail();

  useEffect(function () {
    if (token && email) {
      confirmEmail(
        { token, email },
        {
          onSuccess: (response) => {
            navigate(
              `${response?.roles?.includes("candidate") ? response?.redirectionUrl + "/welcome" : response?.redirectionUrl + "welcome"}`,
              {
                replace: true,
                state: {
                  email_verified: true,
                  loginFirstTime: true,
                  redirectionUrl: `${response?.roles?.includes("candidate") ? response?.redirectionUrl + "/welcome" : response?.redirectionUrl + "welcome"}`,
                },
              },
            );
          },
        },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!token || !email)
    return (
      <div className="m-auto w-full max-w-[45rem] bg-red-o-50 p-8 shadow-md shadow-slate-200">
        <div>
          <div className="mt-3 flex flex-col text-center">
            <h1 className="text-[2rem] font-medium text-red-o-primary">
              Invalid confirmation link
            </h1>
          </div>
        </div>
      </div>
    );

  if (isPending) return <Loader />;

  if (isError)
    return (
      <div className="m-auto w-full max-w-[45rem] bg-red-o-50 p-8 shadow-md shadow-slate-200">
        <div>
          <div className="mt-3 flex flex-col text-center">
            {error?.message && (
              <p className="mb-6 text-lg font-medium text-red-o-primary">
                {error?.message}
              </p>
            )}

            <Link
              className="btn-blue-b-primary mx-auto block rounded-md text-center text-lg font-medium"
              to={"/signup"}
            >
              Create a new account
            </Link>
          </div>
        </div>
      </div>
    );
}

export default ConfirmEmail;
