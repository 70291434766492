/* eslint-disable react/no-unescaped-entities */
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useGetIndustries } from "../../hooks/useGetIndustries";
import { useGetMyCompanyProfile } from "../../hooks/useGetMyCompanyProfile";
import { useOutsideClick } from "../../hooks/useOutsideClick";
import { useSearchCities } from "../../hooks/useSearchCities";
import { useSearchCountries } from "../../hooks/useSearchCountries";
import { useUploadRecruiterLogo } from "../../hooks/useUploadRecruiterLogo";
import CustomDatePick from "../../shared/datepicker/CustomDatePick";
import ControlledDropdown from "../../shared/dropdown/ControlledDropdown";
import FileInput from "../../shared/inputs/FileInput";
import Input from "../../shared/inputs/Input";
import TextArea from "../../shared/inputs/TextArea";
import Loader from "../../shared/loader/Loader";
import { companySizeArray } from "../../utils/constants";

function CompanyProfileInformationStep() {
  const { data: userCompany } = useGetMyCompanyProfile();

  const { setValue, getValues, control, resetField, trigger } =
    useFormContext();

  const { data: industries } = useGetIndustries();

  const { uploadLogo, isPending, isSuccess } = useUploadRecruiterLogo();

  const [logoUrl, setLogoUrl] = useState();

  useEffect(
    function () {
      if (userCompany?.logo) setLogoUrl(userCompany?.logo);
    },
    [userCompany],
  );

  const handleUploadLogo = (e) => {
    const file = e.target.files[0];

    uploadLogo(file, {
      onSuccess: (response) => {
        setLogoUrl(response?.result);
      },
      onError: (err) => {
        console.log(err);
      },
    });
  };

  // Country autocomplete
  const [openCountryList, setOpenCountryList] = useState(false);
  const [countryName, setCountryName] = useState("");

  const { data: searchedCountries, isLoading: isLoadingSearchedCountries } =
    useSearchCountries({ name: countryName });

  const countryList = useOutsideClick(() => setOpenCountryList(false));

  //City autocomplete
  const [openCityList, setOpenCityList] = useState(false);
  const [cityName, setCityName] = useState("");

  const { data: searchedCities, isLoading: isLoadingSearchedCities } =
    useSearchCities({
      countryId: getValues("officeLocation.address.countryId"),
      name: cityName,
    });

  const cityList = useOutsideClick(() => setOpenCityList(false));

  return (
    <div className="flex flex-1 flex-col">
      <div className="mt-4 h-full">
        <div className="flex  flex-col">
          <div className="flex w-full ">
            <div className={`flex  w-full flex-col`}>
              <div className="flex w-full flex-col gap-4 py-4">
                <div className="flex items-center gap-4">
                  {logoUrl && (
                    <div className="h-20 w-20 overflow-hidden ">
                      <img
                        src={logoUrl}
                        className=" object-cover object-center"
                      />
                    </div>
                  )}

                  <FileInput
                    id={`companyLogo`}
                    accept="image/png, image/gif, image/jpeg"
                    label="Company Logo"
                    btnLabel={logoUrl ? "Change logo" : "Upload logo"}
                    controllerName={`companyLogo`}
                    control={control}
                    className={"flex-1"}
                    resetField={resetField}
                    uploadFile={(event) => {
                      handleUploadLogo(event);
                    }}
                    //resetPlaceholder={resetPlaceholder}
                    isUploaded={isSuccess}
                    disabled={isPending}
                  />
                </div>

                <div className="flex items-center gap-2">
                  <Input
                    column={true}
                    label={"Company Name"}
                    type="text"
                    id={"employerName"}
                    placeholder="Enter company name"
                    controllerName={"employerName"}
                    control={control}
                    mainDivClassname={"flex-1"}
                    containerClassName="border-black-g-50"
                    containerBaseClassName="border rounded-md"
                    fieldClassName="input-transparent text-sm font-primary font-normal bg-white "
                    resetField={resetField}
                    gap={0}
                  />
                  <Input
                    column={true}
                    label={"Company email"}
                    type="text"
                    id={"companyEmail"}
                    placeholder="Enter company email"
                    controllerName={"companyEmail"}
                    control={control}
                    containerClassName="border-black-g-50"
                    containerBaseClassName="border rounded-md"
                    mainDivClassname={"flex-1 "}
                    fieldClassName="input-transparent text-sm font-primary font-normal bg-white "
                    resetField={resetField}
                    gap={0}
                  />
                </div>

                <div className="flex items-center gap-2">
                  <ControlledDropdown
                    controllerName="industries"
                    id={"industries"}
                    control={control}
                    items={industries}
                    rules={{ required: "Please select the industry" }}
                    /* defaultValue={getValues("industryId")} */
                    labelName={"Industry"}
                    className=" rounded-[4px] border border-black-g-50   px-2 py-1 font-primary text-sm font-medium"
                  />
                  <Input
                    column={true}
                    label={"Company phone number"}
                    type="number"
                    id={"companyPhone"}
                    placeholder="Enter your phone"
                    controllerName={"companyPhone"}
                    control={control}
                    containerClassName="border-black-g-50"
                    containerBaseClassName="border rounded-md"
                    mainDivClassname={"flex-1 "}
                    fieldClassName="input-transparent text-sm font-primary font-normal bg-white "
                    resetField={resetField}
                    gap={0}
                  />
                </div>

                <div className="flex items-center gap-2">
                  <ControlledDropdown
                    controllerName="companySize"
                    id={"companySize"}
                    control={control}
                    items={companySizeArray}
                    rules={{ required: "Please select the company size" }}
                    /* defaultValue={"" || getValues("companySize")} */
                    labelName={"Size"}
                    className=" rounded-[4px] border border-black-g-50 px-2 py-1 font-primary text-sm font-medium"
                  />
                  <CustomDatePick
                    controllerName={"companyFoundedYear"}
                    labelName={"Founded date"}
                    views={["year", "month"]}
                    inputFormat={"MM/yyyy"}
                    control={control}
                    className="flex-1"
                  />
                </div>

                <Input
                  column={true}
                  label={"Address"}
                  type="text"
                  id={"officeLocation.address.address"}
                  placeholder="Enter your address"
                  controllerName={"officeLocation.address.address"}
                  control={control}
                  containerClassName="border-black-g-50"
                  containerBaseClassName="border rounded-md"
                  mainDivClassname={"flex-1 "}
                  fieldClassName="input-transparent text-sm font-primary font-normal bg-white "
                  resetField={resetField}
                  gap={0}
                />

                <div className="flex items-start gap-2">
                  <div className="relative">
                    <Input
                      label={"Country"}
                      type={"hidden"}
                      control={control}
                      controllerName={"officeLocation.address.countryId"}
                      id={"countryId"}
                      resetField={resetField}
                    />

                    <Input
                      label={"Country"}
                      type={"text"}
                      control={control}
                      autoComplete={"off"}
                      controllerName={"officeLocation.address.countryName"}
                      id={"countryName"}
                      placeholder={"Search country name"}
                      rules={{
                        validate: () => {
                          return (
                            getValues("officeLocation.address.countryId") ||
                            "Please select a country"
                          );
                        },
                        onChange: (e) => {
                          setOpenCountryList(true);
                          setCountryName(e.target?.value);
                          setCityName("");
                          setValue("officeLocation.address.cityId", 0);
                          setValue("officeLocation.address.cityName", "");
                        },
                        onBlur: () => {
                          setValue(
                            "officeLocation.address.countryName",
                            searchedCountries?.[0]
                              ? searchedCountries?.[0]?.name
                              : "",
                          );
                          setValue(
                            "officeLocation.address.countryId",
                            searchedCountries?.[0]
                              ? searchedCountries?.[0]?.id
                              : "",
                          );
                          trigger("officeLocation.address.countryName");
                        },
                      }}
                      resetField={() => {
                        resetField("officeLocation.address.countryId");
                        resetField("officeLocation.address.countryName");
                      }}
                    />
                    {openCountryList && (
                      <div
                        ref={countryList}
                        className="absolute left-0 top-full z-50 min-w-full rounded border border-black-g-50 bg-white shadow-lg"
                      >
                        {isLoadingSearchedCountries && (
                          <div className="p-4">
                            <Loader />
                          </div>
                        )}

                        {searchedCountries?.map((country) => (
                          <div
                            key={country?.id}
                            className="ingml-btn hover:bg-blue-b-50"
                            onClick={() => {
                              setValue(
                                "officeLocation.address.countryId",
                                country?.id,
                              );
                              setValue(
                                "officeLocation.address.countryName",
                                country?.name,
                              );

                              setOpenCountryList(false);
                            }}
                          >
                            {country?.name}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>

                  <div className="relative">
                    <Input
                      label={"State/City"}
                      type={"hidden"}
                      control={control}
                      controllerName={"officeLocation.address.cityId"}
                      id={"cityId"}
                      resetField={resetField}
                    />

                    <Input
                      label={"State/City"}
                      type={"text"}
                      control={control}
                      controllerName={"officeLocation.address.cityName"}
                      id={"cityName"}
                      placeholder={"Search city name"}
                      rules={{
                        validate: () => {
                          return (
                            getValues("officeLocation.address.cityId") ||
                            "Please select a city"
                          );
                        },
                        onChange: (e) => {
                          setOpenCityList(true);
                          setCityName(e.target?.value);
                        },
                        onBlur: () => {
                          setValue(
                            "officeLocation.address.cityName",
                            searchedCities?.[0]
                              ? searchedCities?.[0]?.name
                              : "",
                          );
                          setValue(
                            "officeLocation.address.cityId",
                            searchedCities?.[0] ? searchedCities?.[0]?.id : "",
                          );
                          trigger("officeLocation.address.cityName");
                        },
                      }}
                      resetField={() => {
                        resetField("officeLocation.address.cityId");
                        resetField("officeLocation.address.cityName");
                      }}
                    />

                    {openCityList && (
                      <div
                        ref={cityList}
                        className="absolute left-0 top-full z-50 min-w-full rounded border border-black-g-50 bg-white shadow-lg"
                      >
                        {isLoadingSearchedCities && (
                          <div className="p-4">
                            <Loader />
                          </div>
                        )}
                        {searchedCities?.map((city) => (
                          <div
                            key={city?.id}
                            className="ingml-btn hover:bg-blue-b-50"
                            onClick={() => {
                              setValue(
                                "officeLocation.address.cityId",
                                city?.id,
                              );
                              setValue(
                                "officeLocation.address.cityName",
                                city?.name,
                              );

                              setOpenCityList(false);
                            }}
                          >
                            {city?.name}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>

                  <Input
                    column={true}
                    label={"Zip code"}
                    type="number"
                    id={"officeLocation.address.postalCode"}
                    placeholder="Zip code"
                    controllerName={"officeLocation.address.postalCode"}
                    control={control}
                    mainDivClassname={"flex-1 "}
                    containerClassName="border-black-g-50"
                    containerBaseClassName="border rounded-md"
                    fieldClassName="input-transparent text-sm font-primary font-normal bg-white "
                    resetField={resetField}
                  />
                </div>

                <TextArea
                  label="About Company"
                  id="description"
                  rows={5}
                  placeholder="Describe your company ..."
                  autoComplete="off"
                  controllerName="description"
                  control={control}
                  containerClassName="bg-white border"
                  resetField={resetField}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CompanyProfileInformationStep;
