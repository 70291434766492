function Documentation() {
  return (
    <div className="page-px my-16">
      <h1 className="mb-3 text-xl font-semibold">Buttons</h1>
      <div className="border border-black-g-100 p-4">
        <div className="-m-3 flex flex-wrap">
          <div className="p-3">
            <div className="btn-white">btn-white</div>
          </div>

          <div className="p-3">
            <div className="btn-blue-b-primary">btn-blue-b-primary</div>
          </div>

          <div className="p-3">
            <div className="btn-outline-primary">btn-outline-primary</div>
          </div>

          <div className="p-3">
            <button className="btn-blue-b-primary" disabled>
              Disabled
            </button>
          </div>

          <div className="p-3">
            <div className="btn-blue-b-50">btn-blue-b-50</div>
          </div>

          <div className="p-3">
            <div className="btn-outline-blue-b-50">btn-outline-blue-b-50</div>
          </div>

          <div className="p-3">
            <div className="btn-edit-white">Edit</div>
            <div>btn-edit-white</div>
          </div>

          <div className="p-3">
            <div className="btn-delete">btn-delete</div>
          </div>

          <div className="p-3">
            <div className="btn-outline-blueB">btn-outline-blueB</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Documentation;
