/* eslint-disable react/no-unescaped-entities */
import { PropTypes } from "prop-types";
import { useFormContext } from "react-hook-form";
import Divider from "../../shared/Divider";
import Input from "../../shared/inputs/Input";
import { socialMediaArray } from "../../utils/constants";

function SocialContactStep({ hideClearSection, hideHeader }) {
  const { control, resetField } = useFormContext();

  return (
    <div className="flex flex-1 flex-col">
      {!hideHeader ? (
        <div className="py-4 font-primary text-[25px] font-black leading-10 text-black-g-primary">
          3. Any social media profiles you'd like to share with us?
        </div>
      ) : null}
      <div className="mt-4 h-full">
        <div className="flex  flex-col">
          <div className="flex w-full ">
            <div
              className={`flex ${!hideClearSection ? "w-full" : "w-7/12"} flex-col overflow-hidden`}
            >
              {!hideHeader ? (
                <div className="flex  items-center">
                  <div className="font-primary text-xl font-bold text-black-g-500">
                    Social details
                  </div>
                  <Divider className="ml-4 flex-1" />
                </div>
              ) : null}

              <div className="flex w-full flex-col gap-4 py-4">
                {socialMediaArray?.map((item, index) => (
                  <Input
                    key={item.id}
                    column={true}
                    label={item.name}
                    type="text"
                    id={item.name}
                    placeholder={item.placeholder}
                    controllerName={`socialPlatforms.${index}.url`}
                    control={control}
                    containerClassName="border-black-g-50"
                    containerBaseClassName="border rounded-md"
                    mainDivClassname={"flex-1 "}
                    fieldClassName="input-transparent text-sm font-primary font-normal bg-white "
                    resetField={resetField}
                  />
                ))}
              </div>
            </div>

            {/* {!hideClearSection ? (
              <div className="ml-4 flex w-5/12 flex-col">
                <div className="flex items-center">
                  <div className="font-primary text-base font-light text-blue-b-primary">
                    Clear section
                  </div>
                  <Divider className="ml-4 flex-1" />
                </div>
                <div className="my-4 font-primary text-sm font-light text-[#4A5164]">
                  Here, you'll highlight contact details such as you Email and
                  phone number.
                </div>
              </div>
            ) : null} */}
          </div>
        </div>
      </div>
    </div>
  );
}

SocialContactStep.propTypes = {
  hideClearSection: PropTypes.bool,
  hideHeader: PropTypes.bool,
};
SocialContactStep.defaultProps = {
  hideClearSection: false,
  hideHeader: false,
};
export default SocialContactStep;
