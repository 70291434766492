import React from "react";
import PropTypes from "prop-types";

const LinearProgress = ({ percentage, showProgressLabel }) => {
  let barClass = "";
  switch (true) {
    case percentage >= 80:
      barClass = "bg-[#50FC8A]";
      break;
    case percentage >= 50 && percentage < 80:
      barClass = "bg-[#68FD9B]";
      break;
    case percentage >= 25 && percentage < 50:
      barClass = "bg-[#FDB968]";
      break;
    case percentage >= 10 && percentage < 25:
      barClass = "bg-[#E8FD68]";
      break;
    default:
      break;
  }
  return (
    <div className="progress-container">
      <div
        className={`progress-bar ${barClass}`}
        style={{ width: `${percentage}%` }}
      >
        {showProgressLabel ? <> {percentage}%</> : null}
      </div>
    </div>
  );
};

LinearProgress.propTypes = {
  percentage: PropTypes.number.isRequired,
  showProgressLabel: PropTypes.bool,
};
LinearProgress.defaultProps = {
  showProgressLabel: false,
};

export default LinearProgress;
