import PropTypes from "prop-types";

function MenuIcon({ width, height, className }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 55 39"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_25_31161)">
        <path
          d="M36.0755 10.4198H1.183C0.887296 10.4198 0.295898 10.1221 0.295898 9.52669C0.295898 8.93127 0.591597 8.93127 1.183 8.93127H36.3712C36.6669 8.93127 37.2583 9.22898 37.2583 9.8244C37.2583 10.4198 36.6669 10.4198 36.0755 10.4198Z"
          fill="#0054FF"
        />
        <path
          d="M36.0753 10.7175H1.1828C0.591398 10.7175 0 10.1221 0 9.52671C0 8.93129 0.591398 8.63358 1.1828 8.63358H36.371C36.9624 8.63358 37.5538 9.22899 37.5538 9.82441C37.2581 10.1221 36.6667 10.7175 36.0753 10.7175ZM1.1828 9.22899C0.887097 9.22899 0.591398 9.5267 0.591398 9.82441C0.591398 10.1221 0.887097 10.4198 1.1828 10.4198H36.371C36.6667 10.4198 36.9624 10.1221 36.9624 9.82441C36.9624 9.5267 36.6667 9.22899 36.371 9.22899H1.1828Z"
          fill="#0054FF"
        />
        <path
          d="M36.0755 24.4122H1.183C0.591597 24.4122 0.295898 24.1145 0.295898 23.8168C0.295898 23.5191 0.591597 22.9236 1.183 22.9236H36.3712C36.6669 22.9236 37.2583 23.2214 37.2583 23.8168C36.9626 24.1145 36.6669 24.4122 36.0755 24.4122Z"
          fill="#0054FF"
        />
        <path
          d="M36.0753 24.7099H1.1828C0.591398 24.7099 0 24.4122 0 23.8168C0 23.2214 0.591398 22.6259 1.1828 22.6259H36.371C36.9624 22.6259 37.5538 23.2214 37.5538 23.8168C37.2581 24.4122 36.6667 24.7099 36.0753 24.7099ZM1.1828 23.2214C0.887097 23.2214 0.591398 23.5191 0.591398 23.8168C0.591398 24.1145 0.887097 24.4122 1.1828 24.4122H36.371C36.6667 24.4122 36.9624 24.1145 36.9624 23.8168C36.9624 23.5191 36.6667 23.2214 36.371 23.2214H1.1828Z"
          fill="#0054FF"
        />
        <path
          d="M36.0755 38.7023H1.183C0.887296 38.7023 0.295898 38.4046 0.295898 37.8091C0.295898 37.2137 0.591597 36.916 1.183 36.916H36.3712C36.6669 36.916 37.2583 37.2137 37.2583 37.8091C37.2583 38.4046 36.6669 38.7023 36.0755 38.7023Z"
          fill="#0054FF"
        />
        <path
          d="M36.0753 39H1.1828C0.591398 39 0 38.4046 0 37.8092C0 37.2137 0.591398 36.6183 1.1828 36.6183H36.371C36.9624 36.6183 37.5538 37.2137 37.5538 37.8092C37.5538 38.4046 36.6667 39 36.0753 39ZM1.1828 37.2137C0.887097 37.2137 0.591398 37.5114 0.591398 37.8092C0.591398 38.1069 0.887097 38.4046 1.1828 38.4046H36.371C36.6667 38.4046 36.9624 38.1069 36.9624 37.8092C36.9624 37.5114 36.6667 37.2137 36.371 37.2137H1.1828Z"
          fill="#0054FF"
        />
        <path
          d="M45.538 24.4122H43.4682C43.1725 24.4122 42.5811 24.1145 42.5811 23.5191C42.5811 23.2214 42.8768 22.6259 43.4682 22.6259H45.538C45.8337 22.6259 46.4251 22.9237 46.4251 23.5191C46.4251 24.1145 46.1294 24.4122 45.538 24.4122Z"
          fill="#0054FF"
        />
        <path
          d="M45.5378 24.7099H43.468C42.8766 24.7099 42.2852 24.1145 42.2852 23.5191C42.2852 22.9237 42.8766 22.3282 43.468 22.3282H45.5378C46.1292 22.3282 46.7206 22.9237 46.7206 23.5191C46.7206 24.4122 46.1292 24.7099 45.5378 24.7099ZM43.7637 23.2214C43.468 23.2214 43.1723 23.5191 43.1723 23.8168C43.1723 24.1145 43.468 24.4122 43.7637 24.4122H45.8335C46.1292 24.4122 46.4249 24.1145 46.4249 23.8168C46.4249 23.5191 46.1292 23.2214 45.8335 23.2214H43.7637Z"
          fill="#0054FF"
        />
        <path
          d="M45.538 38.7023H43.4682C43.1725 38.7023 42.5811 38.4046 42.5811 37.8091C42.5811 37.2137 42.8768 36.916 43.4682 36.916H45.538C45.8337 36.916 46.4251 37.2137 46.4251 37.8091C46.4251 38.4046 46.1294 38.7023 45.538 38.7023Z"
          fill="#0054FF"
        />
        <path
          d="M45.5378 39H43.468C42.8766 39 42.2852 38.4046 42.2852 37.8092C42.2852 37.2137 42.8766 36.6183 43.468 36.6183H45.5378C46.1292 36.6183 46.7206 37.2137 46.7206 37.8092C46.7206 38.4046 46.1292 39 45.5378 39ZM43.7637 37.2137C43.468 37.2137 43.1723 37.5114 43.1723 37.8092C43.1723 38.1069 43.468 38.4046 43.7637 38.4046H45.8335C46.1292 38.4046 46.4249 38.1069 46.4249 37.8092C46.4249 37.5114 46.1292 37.2137 45.8335 37.2137H43.7637Z"
          fill="#0054FF"
        />
        <path
          d="M44.9465 11.0153C44.6508 11.0153 44.6508 11.0153 44.3551 10.7176L42.2853 8.9313C41.9896 8.63359 41.9896 8.03817 42.2853 7.74046C42.5809 7.44275 43.1723 7.44275 43.468 7.74046L44.9465 8.9313L53.5218 0.297715C53.8175 4.90248e-06 54.4089 4.90248e-06 54.7046 0.297715C55.0003 0.595425 55.0003 1.19084 54.7046 1.48855L45.5379 10.4199C45.2422 10.7176 45.2422 11.0153 44.9465 11.0153Z"
          fill="#0054FF"
        />
        <path
          d="M44.9461 11.313C44.6504 11.313 44.3547 11.313 44.3547 11.0153L42.2848 8.9313C41.9891 8.63359 41.6934 7.74046 42.2848 7.44275C42.5805 7.14504 42.8762 7.14504 43.1719 7.14504C43.4676 7.14504 43.7633 7.14504 44.059 7.44275L45.2418 8.63359L53.2257 0.29771C53.5214 0 53.8171 0 54.1128 0C54.4085 0 54.7042 0 54.9999 0.29771C55.2956 0.59542 55.2956 0.89313 55.2956 1.19084C55.2956 1.48855 55.2956 1.78626 54.9999 2.08397L45.8332 11.0153C45.5375 11.0153 45.2418 11.313 44.9461 11.313ZM42.8762 7.74046C42.8762 7.74046 42.5805 7.74046 42.5805 8.03817C42.2848 8.33588 42.2848 8.63359 42.5805 8.63359L44.6504 10.4198C44.9461 10.7176 45.2418 10.7176 45.2418 10.4198L54.4085 1.48855V1.19084V0.89313H54.1128H53.8171L45.2418 9.52672H44.9461L43.4676 8.03817C43.1719 7.74046 43.1719 7.74046 42.8762 7.74046Z"
          fill="#0054FF"
        />
      </g>
      <defs>
        <clipPath id="clip0_25_31161">
          <rect width="55" height="39" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

MenuIcon.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
};

export default MenuIcon;
