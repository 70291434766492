/* eslint-disable react-hooks/exhaustive-deps */
import { FormProvider, useForm } from "react-hook-form";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import PhotoInput from "../shared/inputs/PhotoInput";
import { useUser } from "../hooks/useUser";
import { useUploadUserPicture } from "../hooks/useUploadUserPicture";
import { useUserResume } from "../hooks/useUserResume";
import { useUpdateUserResume } from "../hooks/useUpdateUserResume";
import { transformSentSocialPlatforms } from "../utils/helpers";

function UserCandidateProfile() {
  const [stepid, setStepid] = useState(1);
  const location = useLocation();
  const navigate = useNavigate();
  const { user, isLoading: isLoadingUser } = useUser();
  // this api will be triggered only for candidate role
  const { data: userResume, isLoading: isLoadingUserResume } = useUserResume();
  const { updateUserResume } = useUpdateUserResume();

  const {
    uploadUserPicture,
    isUploadingUserPicture,
    uploadedUserPicture,
    resetUploadUserPicture,
  } = useUploadUserPicture();

  const formObj = useForm({
    values: {
      step: stepid,
      id: userResume?.id || 0,
      firstName: userResume?.firstName || "",
      lastName: userResume?.lastName || "",
      birthDate: userResume?.birthDate || "",

      industryId: userResume?.industry?.id || 0,
      educationLevel: userResume?.educationLevel || 0,
      description: userResume?.description || "",
      contactInfo: {
        email: userResume?.contactInfo?.email || "",
        phone: userResume?.contactInfo?.phone || "",
        socialPlatforms: userResume?.socialPlatforms || [],
        address: {
          address: userResume?.contactInfo?.address?.address || "",
          address2: userResume?.contactInfo?.address?.address2 || "",
          cityId: userResume?.contactInfo?.address?.city?.id || 0,
          postalCode: userResume?.contactInfo?.address?.postalCode || "",
          countryId: userResume?.contactInfo?.address?.country?.id || 0,
        },
      },
      experiences: userResume?.experiences || [],
      educations: userResume?.educations || [],
      hardSkills:
        userResume?.hardSkills.map((skill) => {
          return {
            ...skill,
            skillId: skill.id,
            hardSkillProficiencyLevel: skill.hardSkillProficiency,
          };
        }) || [],
      softSkills:
        userResume?.softSkills.map((skill) => {
          return {
            ...skill,
            skillId: skill.id,
            softSkillProficiencyLevel: skill.softSkillProficiency,
          };
        }) || [],
      languages: userResume?.languages || [],
      certifications: userResume?.certifications || [],
      jobTitle: userResume?.jobTitle,
      yearsOfExperience: userResume?.yearsOfExperience || 0,
      disponibility: userResume?.disponibility || 0,
      preferredEmploymentType: userResume?.preferredEmploymentType || 0,
      tjmRange: [userResume?.rate?.min, userResume?.rate?.max],
      salaryRange: [userResume?.rate?.min, userResume?.rate?.max],
      rate: {
        currencyId: userResume?.rate?.currency?.id || 0,
        rateType: 1,
      },
    },
  });
  const sections = [
    {
      name: "Personal information",
      url: "step=1",
      id: 1,
    },
    {
      name: "Contacts",
      url: "step=2",
      id: 2,
    },
    {
      name: "Professional profiles",
      url: "step=3",
      id: 3,
    },
    { name: "Skills", url: "step=4", id: 4 },
    {
      name: "Work experience",
      url: "step=5",
      id: 5,
    },
    {
      name: "Education",
      url: "step=6",
      id: 6,
    },
    // { name: "Recommendations", icon: <FaUser /> },
  ];
  useEffect(() => {
    // Extract stepid from pathname, if no id exists, return 1
    if (location?.pathname && location.pathname.includes("step=")) {
      const stepId = location.pathname.split("=")[1];
      if (stepId !== undefined && stepId !== "") {
        setStepid(Number(stepId));
      } else {
        navigate("step=1", { replace: true }); // Redirect to step 1
      }
    } else {
      navigate("step=1", { replace: true }); // Redirect to step 1 if path doesn't contain step information
    }
  }, [location.pathname]);

  const handleUploadProfilePicture = (e) => {
    const file = e.target?.value;

    uploadUserPicture(file, {
      onSuccess: () => {
        // resetField("profilePicture");
      },
      onError: (err) => {
        if (err?.code === 400)
          formObj.setError(
            "profilePicture",
            { type: "Unsupported", message: err?.message },
            { shouldFocus: true },
          );
        resetUploadUserPicture();
      },
    });
  };
  const saveData = (data) => {
    // Perform your form submission logic here
    try {
      // Submit the form
      const socials = transformSentSocialPlatforms(
        data.contactInfo.socialPlatforms,
      );

      const contactInfo = {
        ...data.contactInfo,
        socialPlatforms: socials?.filter((item) => !!item.url),
      };
      const experiences = data?.experiences?.map((experience) => ({
        ...experience,
        hardSkills: experience?.hardSkills?.map((item) => item.id),
        softSkills: experience?.softSkills?.map((item) => item.id),
      }));
      const rate = {
        ...data.rate,
        min: data?.salaryRange[0],
        max: data?.salaryRange[1],
      };
      updateUserResume({
        ...data,
        contactInfo,
        experiences,
        rate,
        step: stepid,
      });
    } catch (error) {
      // Handle form submission errors
      console.error("Form submission error:", error);
    }
  };
  return (
    <>
      <div className="flex  flex-col ">
        <div className="flex  flex-wrap  md:flex md:w-full ">
          <div className="h-[calc(100vh-101px)] bg-[#f9fafc] md:flex md:w-[20%] md:flex-col">
            <div className="my-10 flex justify-center">
              <PhotoInput
                control={formObj.control}
                id="profilePicture"
                controllerName="profilePicture"
                rules={{
                  onChange: handleUploadProfilePicture,
                }}
                isUploading={isUploadingUserPicture}
                disabled={isUploadingUserPicture}
                src={
                  uploadedUserPicture?.result ||
                  user?.profilePic ||
                  "https://placehold.co/200x200"
                }
              />
            </div>
            <div className="mx-4 bg-white md:flex md:flex-col md:justify-center">
              {sections.map((section, index) => (
                <Link
                  key={section.id}
                  to={section.url}
                  className={`border-r border-t ${index == sections.length - 1 && "border-b"} ${stepid === section.id ? "border-l-4 border-l-blue-b-primary " : "border-l"} font-primary text-base font-normal text-black-g-primary md:p-3 `}
                >
                  {section.name}
                </Link>
              ))}
            </div>
          </div>
          <div className="h-[calc(100vh-120px)] w-[80%] overflow-y-auto overflow-x-hidden">
            <div className="md:w-3/4 md:px-8">
              <FormProvider {...formObj}>
                <Outlet />
                <div className="my-4 flex w-full justify-end">
                  <button
                    onClick={() => formObj.handleSubmit(saveData)()}
                    className="btn-blue-b-primary flex w-fit items-center justify-center rounded-md py-2 font-primary text-base font-medium"
                  >
                    Save
                  </button>
                </div>
              </FormProvider>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UserCandidateProfile;
