import Divider from "../../shared/Divider";
import Input from "../../shared/inputs/Input";
import ControlledDropdown from "../../shared/dropdown/ControlledDropdown";
import { useGetCountries } from "../../hooks/useGetCountries";
import { useFormContext } from "react-hook-form";
import { useGetCities } from "../../hooks/useGetCities";
import { PropTypes } from "prop-types";

function ContactsInfoStep({ hideClearSection, hideHeader }) {
  const { data: countries } = useGetCountries();

  const { watch, getValues, control, resetField } = useFormContext();

  const { data: cities } = useGetCities(watch("contactInfo.address.countryId"));

  return (
    <div className="flex flex-1 flex-col">
      {!hideHeader ? (
        <div className="py-4 font-primary text-[25px] font-black leading-10 text-black-g-primary">
          2. How can we reach you ?
        </div>
      ) : null}

      <div className="mt-4 h-full">
        <div className="flex  flex-col">
          <div className="flex w-full ">
            <div
              className={`flex ${!hideClearSection ? "w-full" : "w-7/12"} flex-col overflow-hidden`}
            >
              <div className="flex  items-center">
                <div className="font-primary text-xl font-bold text-black-g-500">
                  Contact details
                </div>
                <Divider className="ml-4 flex-1" />
              </div>
              <div className="flex w-full flex-col gap-4 py-4">
                <Input
                  column={true}
                  label={"Your email"}
                  type="text"
                  id={"email"}
                  placeholder="Enter email"
                  controllerName={"contactInfo.email"}
                  control={control}
                  containerClassName="border-black-g-50"
                  containerBaseClassName="border rounded-md"
                  mainDivClassname={"flex-1 "}
                  fieldClassName="input-transparent text-sm font-primary font-normal bg-white "
                  resetField={resetField}
                />
                <Input
                  column={true}
                  label={"Phone number"}
                  type="number"
                  id={"phone"}
                  placeholder="Enter your phone"
                  controllerName={"contactInfo.phone"}
                  control={control}
                  containerClassName="border-black-g-50"
                  containerBaseClassName="border rounded-md"
                  mainDivClassname={"flex-1 "}
                  fieldClassName="input-transparent text-sm font-primary font-normal bg-white "
                  resetField={resetField}
                />
                <Input
                  column={true}
                  label={"Adress"}
                  type="text"
                  id={"contactInfo.address.address"}
                  placeholder="Enter your address"
                  controllerName={"contactInfo.address.address"}
                  control={control}
                  containerClassName="border-black-g-50"
                  containerBaseClassName="border rounded-md"
                  mainDivClassname={"flex-1 "}
                  fieldClassName="input-transparent text-sm font-primary font-normal bg-white "
                  resetField={resetField}
                />
                <div className="flex items-center gap-2">
                  <ControlledDropdown
                    controllerName={"contactInfo.address.countryId"}
                    id={"contactInfo.address.countryId"}
                    control={control}
                    items={countries}
                    defaultValue={getValues("contactInfo.address.countryId")}
                    labelName={"Country"}
                    placeholder={"Country"}
                    className=" rounded-md border border-black-g-50 px-2 py-1 font-primary text-sm font-medium"
                  />

                  <ControlledDropdown
                    controllerName="contactInfo.address.cityId"
                    id={"contactInfo.address.cityId"}
                    control={control}
                    placeholder={"City"}
                    items={cities}
                    defaultValue={getValues("contactInfo.address.cityId")}
                    labelName={"State/City"}
                    className=" rounded-md border border-black-g-50 px-2 py-1 font-primary text-sm font-medium"
                  />
                  <Input
                    column={true}
                    label={"Zip code"}
                    type="number"
                    id={"contactInfo.address.postalCode"}
                    placeholder="Zip code"
                    controllerName={"contactInfo.address.postalCode"}
                    control={control}
                    mainDivClassname={"flex-1 "}
                    containerClassName="border-black-g-50"
                    containerBaseClassName="border rounded-md"
                    fieldClassName="input-transparent text-sm font-primary font-normal bg-white "
                    resetField={resetField}
                  />
                </div>
              </div>
            </div>

            {/* {!hideClearSection ? (
              <div className="ml-4 flex w-5/12 flex-col">
                <div className="flex items-center">
                  <div className="font-primary text-base font-light text-blue-b-primary">
                    Clear section
                  </div>
                  <Divider className="ml-4 flex-1" />
                </div>
                <div className="my-4 font-primary text-sm font-light text-[#4A5164]">
                  Here, you&apos;ll highlight contact details such as you Email
                  and phone number.
                </div>
              </div>
            ) : null} */}
          </div>
        </div>
      </div>
    </div>
  );
}

ContactsInfoStep.propTypes = {
  hideClearSection: PropTypes.bool,
  hideHeader: PropTypes.bool,
};
ContactsInfoStep.defaultProps = {
  hideClearSection: false,
  hideHeader: false,
};

export default ContactsInfoStep;
