import { Dialog, DialogActions } from "@mui/material";
import Slide from "@mui/material/Slide";
import { forwardRef, useState } from "react";
import { HiMiniEllipsisVertical } from "react-icons/hi2";
import { Link, useNavigate } from "react-router-dom";
import ApplicationForm from "../components/application/ApplicationForm";
import CandidateCards from "../components/jobDescription/job-description-details/CandidateCards";
import JobsCards from "../components/jobDescription/job-description-details/JobsCards";
import { useApplicationCheck } from "../hooks/useApplicationCheck";
import { useIsAuthenticated } from "../hooks/useIsAuthenticated";
import { useJobDescriptionSingle } from "../hooks/useJobDescriptionSingle";
import { usePublishJob } from "../hooks/usePublishJob";
import { useRemoveJobDescription } from "../hooks/useRemoveJobDescription";
import Divider from "../shared/Divider";
import Dropdown from "../shared/dropdown/Dropdown";
import CustomImage from "../shared/images/CustomImage";
import CustomEditor from "../shared/inputs/CustomEditor";
import LoaderFullPage from "../shared/loader/LoaderFullPage";
import Menus from "../shared/Menus";
import Modal from "../shared/Modal";
import {
  EmploymentTypeEnum,
  ExperienceLevelEnum,
  WorkLocationEnum,
  jobDetailStatusFilter,
} from "../utils/constants";
import { getFileType } from "../utils/helpers";
import { useUser } from "../hooks/useUser";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function JobDescriptionSingle() {
  const { user } = useUser();

  const { isLoading, data, error } = useJobDescriptionSingle();
  const navigate = useNavigate();
  const { deleteJob } = useRemoveJobDescription();
  const { publishJob } = usePublishJob();
  const { redirectionUrl, roles } = useIsAuthenticated();
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const {
    id,
    title,
    workLocation,
    rate,
    employmentType,
    experienceLevel,
    company,
    mainMission,
    responsibilities,
    skills,
    profile,
    isPublished,
    country,
  } = data || {};

  const ellipsisOptions = [
    {
      label: "Edit",
      onClick: () => navigate(`${redirectionUrl}/edit-job/${id}`),
    },
    { label: "Delete", onClick: () => handleClickOpen() },
  ];

  const handleDelete = () => {
    deleteJob(
      { id },
      {
        onSuccess: () => {
          handleClose();
          navigate("/recruiter/jobs");
        },
      },
    );
  };

  const handlePublish = () => {
    publishJob(
      { id },
      {
        onSuccess: () => {
          navigate("/recruiter/jobs");
        },
      },
    );
  };

  const { isLoading: isLoadingAppCheck, data: dataAppCheck } =
    useApplicationCheck(id);

  if (isLoading) return <LoaderFullPage />;

  if (error)
    return (
      <div className="page-px my-16">
        <p className="text-center text-2xl font-bold text-red-600">
          {error?.message}
        </p>
      </div>
    );

  if (data)
    return (
      <div className="page-px flex w-full flex-col gap-4 py-4">
        <div className="mb-4 flex flex-1 flex-row flex-wrap items-center gap-2 ">
          <div className="flex flex-1 flex-col gap-4 lg:flex-row lg:items-center">
            <span className="lg:title-primary-300-4xl text-3xl font-medium text-black-g-primary">
              {title}
            </span>
            <div className="flex items-center gap-2">
              <span className="rounded bg-[#FFEDE3] px-2 py-1 font-primary text-xs font-medium text-[#914E14]">
                {EmploymentTypeEnum[employmentType]}
              </span>
              <span className="rounded bg-[#E3FFF0] px-2 py-1 font-primary text-xs font-medium text-[#098D2E]">
                {ExperienceLevelEnum[experienceLevel]}
              </span>
              <span className="rounded bg-[#F1E3FF] px-2 py-1 font-primary text-xs font-medium text-[#500F8F]">
                {WorkLocationEnum[workLocation]}
              </span>
            </div>
          </div>
          <div className="flex items-center  gap-2 lg:justify-end">
            {roles?.includes("recruiter") ? (
              <>
                {isPublished ? (
                  <Dropdown
                    className="rounded border   bg-[#E7F9F6] px-2 font-primary text-base font-medium text-[#098D2E]"
                    label="Status"
                    optionsConfig={{
                      options: jobDetailStatusFilter,
                      idKey: "id",
                      nameKey: "name",
                    }}
                    // defaulLabel={"Open"}
                    defaultSelected={2}
                    hoverItemColor="hover:bg-[#95D2B3]"
                    selectedItemBg="bg-[#55AD9B]"
                    onSelect={() => {}}
                  />
                ) : (
                  <div
                    className="btn-blue-b-primary rounded  py-2 font-primary text-base"
                    onClick={handlePublish}
                  >
                    Publish
                  </div>
                )}
                <Menus>
                  <Menus.Menu>
                    <Menus.Toggle id={"ellipsis-menu"}>
                      <HiMiniEllipsisVertical size={30} />
                    </Menus.Toggle>
                    <Menus.List id={"ellipsis-menu"} position="left">
                      {ellipsisOptions.map((option, index) => (
                        <Menus.Button
                          key={index}
                          onClick={option?.onClick}
                          className="cursor-pointer px-4 py-2 hover:bg-blue-b-50"
                        >
                          {option?.label}
                        </Menus.Button>
                      ))}
                    </Menus.List>
                  </Menus.Menu>
                </Menus>
              </>
            ) : isLoadingAppCheck ? (
              <div className="flex items-center rounded border border-blue-b-75 bg-blue-b-50 px-3 py-2">
                Checking Application ...
              </div>
            ) : !dataAppCheck?.applicationExist ? (
              <Modal>
                {user ? (
                  <Modal.Open windowId={"application_form"}>
                    <div className="btn-outline-blueB flex items-center rounded px-3 py-2">
                      Apply
                    </div>
                  </Modal.Open>
                ) : (
                  <Link
                    className="btn-outline-blueB flex items-center rounded px-3 py-2"
                    to={"/login"}
                    state={{ redirectionUrl: `/candidate/job-detail/${id}` }}
                  >
                    Apply
                  </Link>
                )}

                <Modal.Window
                  id={"application_form"}
                  className="w-full max-w-screen-lg p-6"
                >
                  <div className="overflow-hidden">
                    <div className="-m-4 flex">
                      <div className="flex-1 p-4">
                        {title && (
                          <h5 className="font-semibold capitalize">{title}</h5>
                        )}
                        <div className="mt-1 flex items-center space-x-3">
                          {getFileType(company?.logo) === "image" && (
                            <img
                              className="h-8 w-8 object-contain object-center"
                              src={company?.logo}
                            />
                          )}
                          <p className="text-sm">{company?.name}</p>
                        </div>
                      </div>
                      <div className="ml-auto p-4">
                        <Modal.Close />
                      </div>
                    </div>
                  </div>

                  <Divider className="mb-8 mt-2" />
                  <ApplicationForm jobDescriptionId={id} />
                </Modal.Window>
              </Modal>
            ) : (
              <div className="flex items-center rounded border border-blue-b-75 bg-blue-b-50 px-3 py-2">
                Application sent
              </div>
            )}
          </div>
        </div>
        <div className="flex w-full flex-col gap-2 lg:flex-row">
          <div className="flex w-full flex-col gap-3 lg:w-[60%] xl:w-[65%] 2xl:w-[70%]">
            <div className="flex flex-wrap gap-3 rounded-lg border-black-g-50 bg-[#F7F9FB] p-5 lg:gap-0">
              <div className="flex flex-col gap-2 border-r border-black-g-50 pr-4">
                <span className="font-primary text-sm font-normal text-blue-b-primary">
                  Company
                </span>
                <div className="flex items-center gap-2">
                  <CustomImage
                    imgClassName={"image-layout w-16 h-16"}
                    imgSrc={company?.logo || "https://placehold.co/200x200"}
                  />
                  <div className="flex flex-col gap-1">
                    <span className="font-primary text-base font-semibold text-[#1C2436]">
                      {company?.name || "No company"}
                    </span>
                    <span className="font-primary text-xs font-normal text-black-g-100">
                      {company?.location
                        ? company?.location?.address?.country?.name
                        : "No Country detail"}
                    </span>
                  </div>
                </div>
              </div>
              <div className="flex  flex-col gap-4  px-4">
                <span className="font-primary text-sm font-normal text-blue-b-primary">
                  Salary
                </span>
                <div className="flex items-center gap-2">
                  {rate ? (
                    <div className="flex flex-col gap-1">
                      <span className="font-primary text-base font-semibold text-[#1C2436]">
                        {rate?.min} - {rate?.max}{" "}
                        <span className="font-primary text-xs font-medium text-black-g-100">
                          {rate?.currency?.symbol}
                        </span>
                      </span>
                      {employmentType > 0 && (
                        <span className="font-primary text-xs font-normal text-black-g-100">
                          {EmploymentTypeEnum[employmentType]}
                        </span>
                      )}
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="flex flex-1 flex-col gap-2 sm:gap-4 sm:border-l sm:border-black-g-50 sm:pl-4">
                <span className="font-primary text-sm font-normal text-blue-b-primary">
                  Job Location
                </span>
                <div className="flex items-center gap-2">
                  <span className="font-primary text-sm font-normal text-black-g-100">
                    {country?.name}
                  </span>
                </div>
              </div>

              {/* {roles?.includes("recruiter") ? (
                <div className="flex flex-1 flex-col gap-2 sm:gap-4 sm:border-l sm:border-black-g-50 sm:pl-4">
                  <span className="font-primary text-sm font-normal text-blue-b-primary">
                    Note
                  </span>
                  <div className="flex items-center gap-2">
                    <span className="font-primary text-sm font-normal text-black-g-100">
                      Craft intuitive digital experiences, collaborate
                      cross-functionally, iterate based on user feedback, and
                      communicate effectively.
                    </span>
                  </div>
                </div>
              ) : null} */}
            </div>
            <div className="flex flex-col  rounded-lg border-black-g-50 bg-[#F7F9FB] p-5">
              <div className="flex flex-1 items-center">
                <span className="flex-1 font-primary text-xl font-medium text-[#1C2436]">
                  Global Mission
                </span>
                {/* {roles?.includes("recruiter") ? (
                  <div className="btn-outline-blueB flex items-center gap-2 rounded-[4px] px-3 font-medium">
                    <BsMagic />
                    Generate with AI
                  </div>
                ) : null} */}
              </div>
              <CustomEditor
                editorClassName={`input-transparent h-auto  p-0 bg-transprent border-transparent ${mainMission.raw == "" && "h-0"}`}
                readOnly
                toolbarHidden
                htmlDefaultValue={
                  mainMission ? mainMission.html : "No description"
                }
              />
            </div>
            <div className="flex flex-col  rounded-lg border-black-g-50 bg-[#F7F9FB] p-5">
              <div className="flex flex-1 items-center">
                <span className="flex-1 font-primary text-xl font-medium text-[#1C2436]">
                  Responsibilities
                </span>
                {/* {roles?.includes("recruiter") ? (
                  <div className="btn-outline-blueB flex items-center gap-2 rounded-[4px] px-3 font-medium">
                    <BsMagic />
                    Generate with AI
                  </div>
                ) : null} */}
              </div>

              <CustomEditor
                editorClassName={`input-transparent h-auto  p-0 bg-transprent border-transparent ${responsibilities.raw == "" && "h-0"}`}
                readOnly
                toolbarHidden
                htmlDefaultValue={
                  responsibilities ? responsibilities.html : "No description"
                }
              />
            </div>
            <div className="flex flex-col  rounded-lg border-black-g-50 bg-[#F7F9FB] p-5">
              <div className="flex flex-1 items-center">
                <span className="flex-1 font-primary text-xl font-medium text-[#1C2436]">
                  Profile
                </span>
                {/* {roles?.includes("recruiter") ? (
                  <div className="btn-outline-blueB flex items-center gap-2 rounded-[4px] px-3 font-medium">
                    <BsMagic />
                    Generate with AI
                  </div>
                ) : null} */}
              </div>
              <CustomEditor
                editorClassName={`input-transparent h-auto  p-0 bg-transprent border-transparent ${profile.raw == "" && "h-0"}`}
                readOnly
                toolbarHidden
                htmlDefaultValue={profile ? profile.html : "No description"}
              />
            </div>
            <div className="flex flex-col  rounded-lg border-black-g-50 bg-[#F7F9FB] p-5">
              <div className="mb-4 flex flex-1 items-center">
                <span className="flex-1 font-primary text-xl font-medium text-[#1C2436]">
                  Soft Skills
                </span>
                {/* {roles?.includes("recruiter") ? (
                  <div className="btn-outline-blueB flex items-center gap-2 rounded-[4px] px-3 font-medium">
                    <BsMagic />
                    Generate with AI
                  </div>
                ) : null} */}
              </div>

              <div className="flex flex-wrap items-center gap-3">
                {skills?.length > 0 ? (
                  <>
                    {skills?.map((item) => (
                      <div
                        key={item.id}
                        className="mb-3 rounded bg-blue-b-50 p-2 font-primary text-sm font-medium text-blue-b-primary"
                      >
                        {item?.name}
                      </div>
                    ))}
                  </>
                ) : (
                  "No Skills found"
                )}
              </div>
            </div>
          </div>
          <div className="w-full lg:w-[40%] xl:w-[35%] 2xl:w-[30%]">
            {roles?.includes("recruiter") ? (
              <CandidateCards
                redirectionUrl={redirectionUrl}
                roles={roles}
                jobId={id}
              />
            ) : (
              <JobsCards redirectionUrl={redirectionUrl} roles={roles} />
            )}
          </div>
        </div>
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <div className="flex flex-col p-4">
            <div className="mb-4 font-primary text-xl font-medium text-black-g-500">
              Confirm Delete
            </div>
            <div className="mb-4 font-primary text-xl font-normal">
              Are you sure you want to delete this job?
            </div>
            <DialogActions>
              <div
                className="btn-white flex  items-center justify-center rounded-md font-primary text-base font-medium"
                onClick={handleClose}
              >
                Cancel
              </div>
              <div
                className="btn-red-o-primary flex items-center  justify-center rounded-md  font-primary text-base font-medium text-white"
                onClick={handleDelete}
              >
                Delete
              </div>
            </DialogActions>
          </div>
        </Dialog>
      </div>
    );
}

export default JobDescriptionSingle;
