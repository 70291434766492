import React from "react";
import PropTypes from "prop-types";
import Divider from "../../shared/Divider";

function SectionTitle({ title }) {
  return (
    <div className="flex w-full items-center">
      <Divider className="m-0 w-8" />
      <div className="mx-2 font-primary text-base font-bold text-black-g-400">
        {title}
      </div>
      <Divider className="m-0 flex-1" />
    </div>
  );
}

SectionTitle.propTypes = {
  title: PropTypes.string.isRequired,
};

export default SectionTitle;
