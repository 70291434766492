import PropTypes from "prop-types";
import { useState } from "react";
import { HiOutlineStar, HiStar } from "react-icons/hi2";

function StarRating({
  maxRating = 5,
  size = 6,
  color = "#0054ff",
  strokeWidth = 1.5,
  fontSize = "1rem",
  defaultRating = 0,
  showDefaultAmount = true,
  disableInteraction = false,
}) {
  const [rating, setRating] = useState(defaultRating);
  const [tempRating, setTempRating] = useState(0);

  const handleStarClick = (index) => {
    if (!disableInteraction) {
      setRating(index + 1);
    }
  };

  const handleStarMouseEnter = (index) => {
    if (!disableInteraction) {
      setTempRating(index + 1);
    }
  };

  const handleStarMouseLeave = () => {
    if (!disableInteraction) {
      setTempRating(0);
    }
  };
  return (
    <div className="overflow-hidden">
      <div className="-m-2 flex items-center">
        <div className="overflow-hidden p-2">
          <div className="-m-0.5 flex items-center">
            {Array.from({ length: maxRating }, (_, i) => (
              <div
                key={i}
                className="p-0.5"
                role={disableInteraction ? "none" : "button"}
                onClick={() => handleStarClick(i)}
                onMouseEnter={
                  disableInteraction ? null : () => handleStarMouseEnter(i)
                }
                onMouseLeave={disableInteraction ? null : handleStarMouseLeave}
              >
                <div
                  style={{
                    width: `${parseFloat(size) / 4}rem`,
                    height: `${parseFloat(size) / 4}rem`,
                    color: color,
                  }}
                >
                  {(tempRating ? tempRating >= i + 1 : rating >= i + 1) ? (
                    <HiStar className="h-full w-full" />
                  ) : (
                    <HiOutlineStar
                      className="h-full w-full"
                      style={{
                        strokeWidth: strokeWidth,
                      }}
                    />
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
        {showDefaultAmount ? (
          <>
            {(tempRating > 0 || rating > 0) && (
              <div className="p-2" style={{ color: color, fontSize: fontSize }}>
                {tempRating || rating}/{maxRating}
              </div>
            )}
          </>
        ) : null}
      </div>
    </div>
  );
}

StarRating.propTypes = {
  maxRating: PropTypes.number,
  size: PropTypes.number,
  color: PropTypes.string,
  strokeWidth: PropTypes.number,
  fontSize: PropTypes.string,
  defaultRating: PropTypes.number,
};

export default StarRating;
