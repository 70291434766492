import { Link, useLocation, useNavigate } from "react-router-dom";
import { HiCheck, HiOutlineDocumentText } from "react-icons/hi2";
import LogoIcon from "../../shared/logo/LogoIcon";
import { getFileType } from "../../utils/helpers";
import { useUser } from "../../hooks/useUser";
import Loader from "../../shared/loader/Loader";
import UserIcon from "../../icons/UserIcon";
import { useParseResume } from "../../hooks/useParseResume";

function LoginFirstTime() {
  const location = useLocation();
  const { isLoading, user } = useUser();

  const { firstName, lastName, profilePic } = user || {};

  const {
    parseResume,
    isPending: isParsingResume,
    isSuccess,
    data,
    reset: resetParseResume,
  } = useParseResume();

  const navigate = useNavigate();

  const uploadResume = (e) => {
    const file = e.target.files[0];

    parseResume(file, {
      onSuccess: (data) => {
        navigate("/add-profile", {
          replace: true,
          state: { parsedResumeData: data },
        });
      },
      onSettled: () => resetParseResume(),
    });
  };
  //if (!location?.state?.loginFirstTime) return <Navigate to={"/"} />;

  if (isLoading)
    return (
      <section>
        <div className="page-px flex min-h-screen items-center justify-center bg-[#F7F9FB] py-16">
          <Loader />
        </div>
      </section>
    );

  if (Object.keys?.(user)?.length > 0)
    return (
      <section>
        <div className="page-px flex min-h-screen bg-[#F7F9FB] py-16">
          <div className="flex flex-1 items-center justify-center">
            <div className="m-auto w-full max-w-[45rem] bg-white p-8 shadow-md shadow-slate-200">
              <div className="mb-6 flex justify-center">
                <LogoIcon size={18} className="mx-auto" />
              </div>

              <div className="mb-8 flex flex-col text-center">
                <h1 className="text-[2rem] font-bold">Welcome !</h1>
              </div>

              <div className="mb-10 flex justify-center">
                <div className="h-36 w-36 rounded-full">
                  <div className="h-full w-full overflow-hidden rounded-full">
                    {getFileType(profilePic) === "image" ? (
                      <img
                        src={profilePic}
                        className="h-full w-full object-cover object-center"
                      />
                    ) : (
                      <div className="h-full w-full bg-[#C4C4C4] p-2">
                        <UserIcon className={"text-black-g-50"} />
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <p className="my-10 text-center text-2xl font-medium">
                {`${firstName} ${lastName}`}
              </p>

              <div className="my-10">
                <ul className="-m-2 flex flex-col">
                  <li className="flex space-x-2 p-2">
                    <HiCheck className="h-5 w-5 stroke-1 text-green-g-primary" />
                    <p className="font-light">
                      Be sure to enter your actual data, this will help the
                      employer contact you faster
                    </p>
                  </li>

                  <li className="flex space-x-2 p-2">
                    <HiCheck className="h-5 w-5 stroke-1 text-green-g-primary" />
                    <p className="font-light">
                      Upload your CV to make the profile process faster
                    </p>
                  </li>
                </ul>
              </div>

              <div className="my-10">
                <div className="-m-4 flex justify-center">
                  <div className="p-4">
                    {isParsingResume ? (
                      <button
                        htmlFor="parse-resume"
                        className={`btn-outline-primary flex h-full items-center space-x-4 rounded-md text-lg font-medium shadow-md`}
                        disabled
                      >
                        <div>Upload CV</div>
                        <HiOutlineDocumentText className="h-8 w-8" />
                      </button>
                    ) : (
                      <label
                        htmlFor="parse-resume"
                        className={`btn-outline-primary flex h-full items-center space-x-4 rounded-md text-lg font-medium shadow-md`}
                      >
                        <div>Upload CV</div>
                        <HiOutlineDocumentText className="h-8 w-8" />
                      </label>
                    )}

                    <input
                      type="file"
                      hidden
                      id="parse-resume"
                      onChange={(e) => uploadResume(e)}
                      disabled={isParsingResume}
                    />
                  </div>
                  <div className="p-4">
                    <Link
                      type="button"
                      className="btn-blue-b-primary flex h-full items-center space-x-4 rounded-md border border-blue-b-primary text-lg font-medium shadow-md"
                      to={"/add-profile"}
                    >
                      Create profile manually
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
}

export default LoginFirstTime;
