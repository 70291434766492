/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from "prop-types";
import LinearProgress from "../../shared/progress/LinearProgress";
import { CiCalendar, CiLocationOn } from "react-icons/ci";
import { FaFacebookF, FaInstagram, FaLinkedin } from "react-icons/fa6";
import SectionTitle from "./SectionTitle";
import MenuIcon from "../../icons/MenuIcon";
import {
  detectAndParseDate,
  getFileType,
  transformSentSocialPlatforms,
} from "../../utils/helpers";
import { useGetIndustries } from "../../hooks/useGetIndustries";
import { useGetCountries } from "../../hooks/useGetCountries";
import { useGetCities } from "../../hooks/useGetCities";
import { TfiWorld } from "react-icons/tfi";

import { Radar } from "react-chartjs-2";
import { useEffect, useState } from "react";
import StarRating from "../../shared/StarRating";

function SliderInfo({ currentStep, totalSteps, formObj, user }) {
  const { watch } = formObj;
  const {
    experiences,
    educations,
    industryId,
    contactInfo: {
      socialPlatforms,
      address: { countryId, cityId },
    },
    hardSkills,
    softSkills,
    languages,
  } = watch();

  const { data: industries, isLoading: isLoadingIndustries } =
    useGetIndustries();
  const { data: countries } = useGetCountries();
  const { data: cities, isLoading: isCityLoading } = useGetCities(countryId);

  const transformedSocials = transformSentSocialPlatforms(socialPlatforms);

  const [chartDataset, setChartDataset] = useState([]);
  const [labelsChartset, setLabelsChartset] = useState([]);
  const skills = [...hardSkills, ...softSkills];

  useEffect(() => {
    if (hardSkills || softSkills) {
      const skillsDataset = {
        data: skills?.map(
          (skill) =>
            skill.hardSkillProficiencyLevel || skill.softSkillProficiencyLevel,
        ),
        fill: true,
        backgroundColor: "#cad5e7",
        borderColor: "#bcc7d9",
        pointBackgroundColor: "#456fad",
        pointBorderColor: "#fff",
        pointHoverBackgroundColor: "#fff",
        pointHoverBorderColor: "#456fad",
      };
      setChartDataset([skillsDataset]);
      const labels = skills.map((item) => item.name);
      setLabelsChartset(labels);
    }
  }, [hardSkills, softSkills]);

  // Chart options
  const options = {
    maintainAspectRatio: false, // Disable aspect ratio to control the chart's dimensions
    aspectRatio: 1, // Set the aspect ratio (width/height) of the chart. Adjust as needed.
    plugins: {
      legend: {
        display: false, // Hide legend labels
      },
    },
    scales: {
      r: {
        grid: { circular: true, color: "#939393" },
        angleLines: { color: "#939393" },
        ticks: {
          stepSize: 1,
          min: 1, // Set the minimum value of the scale
          max: 4, // Set the maximum value of the scale
          maxRotation: 0, // Prevent automatic rotation of tick labels
          minRotation: 0, // Prevent automatic rotation of tick labels
        },
      },
    },
  };

  const socialPlatformIcons = {
    1: <TfiWorld size={21} color="#999da6" />,
    2: <FaFacebookF size={21} color="#999da6" />,
    3: <FaInstagram size={21} color="#999da6" />,
    4: <FaLinkedin size={21} color="#999da6" />,
  };

  return (
    <div className="flex w-1/4 flex-col rounded-bl-md rounded-tl-md shadow-slider-shadow">
      <div className="flex h-full flex-col gap-4 overflow-auto">
        <div className="flex items-center gap-3 py-8 pl-8">
          <img
            className="h-[80px] w-[80px] rounded-full object-cover object-center"
            src={
              getFileType(user?.profilePic) === "image"
                ? user?.profilePic
                : "/uploads/avatar.png"
            }
          />
          <div className="flex flex-col">
            <div className="font-primary text-xl font-black text-black-g-primary">
              {user?.firstName
                ? user?.firstName + " " + user?.lastName
                : "John doe"}
            </div>
            {!isLoadingIndustries && industryId != 0 ? (
              <div className="font-primary text-xl font-normal text-[#4A5164]">
                {
                  industries?.find((industry) => industry?.id === industryId)
                    ?.name
                }
              </div>
            ) : null}
          </div>
        </div>
        <div className="flex w-full  items-center  gap-8 px-8">
          {!isCityLoading && cityId ? (
            <div className="flex  items-center gap-2 font-primary text-base font-normal text-black-g-75">
              <CiLocationOn size={17} />
              {
                countries?.find((country) => country?.id === countryId)?.name
              }, {cities?.find((city) => city?.id === cityId)?.name}
            </div>
          ) : null}

          {/* <div className="flex  items-center gap-2 font-primary text-base font-normal text-black-g-75">
            <MdOutlineWorkOutline size={17} />2 years
          </div> */}
        </div>
        <div className="flex flex-1 flex-col justify-center  gap-4">
          {(skills && skills.length > 0) ||
          (educations && educations.length > 0) ||
          (experiences && experiences.length > 0) ||
          (languages && languages.length > 0) ||
          (transformedSocials && transformedSocials.length > 0) ? (
            <div className="flex flex-1 flex-col  gap-4">
              {transformedSocials && transformedSocials?.length > 0 ? (
                <>
                  <SectionTitle title="Social contact" />
                  <div className="flex items-center  gap-4 px-8 py-1">
                    {transformedSocials
                      ?.filter((platform) => platform.url)
                      .map((socialPlatform) => (
                        <a
                          key={socialPlatform.id}
                          href={`http://${socialPlatform.url}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {socialPlatformIcons[socialPlatform.id]}
                        </a>
                      ))}
                  </div>
                </>
              ) : null}

              {experiences && experiences.length > 0 ? (
                <>
                  <SectionTitle title="Experiences" />
                  <div className="flex flex-col flex-wrap  gap-2 px-8 py-1">
                    {experiences
                      ?.filter(
                        (obj) =>
                          (obj.name !== "" && obj.name !== undefined) ||
                          (obj.companyName !== "" &&
                            obj.companyName !== undefined),
                      )
                      .map((item, index) => (
                        <div
                          key={index}
                          className="flex-1 rounded-lg border border-[#E0E0E0] bg-[#F7F9FB] px-3 py-2 font-primary text-base font-light shadow-experience-card"
                        >
                          <div className="flex items-center gap-4">
                            <div className="flex items-center gap-2">
                              <img
                                className="h-[26px] w-[26px] rounded-md bg-white object-contain"
                                src={
                                  item?.companyLogo ||
                                  "https://placehold.co/40x40"
                                }
                              />
                              <div className="flex-1 font-primary text-sm font-bold text-[#4A5164]">
                                {item?.companyName}
                              </div>
                            </div>
                            <div className="flex-1 font-primary text-sm font-light text-[#4A5164]">
                              {item?.name}
                            </div>
                            <div className="flex items-center gap-2">
                              <CiCalendar size={14} />
                              {item?.startDate != "" || !item?.endDate != "" ? (
                                <div className="font-primary text-xs font-light text-[#4A5164]">
                                  {item.startDate
                                    ? detectAndParseDate(item.startDate)
                                    : "N/A"}{" "}
                                  -
                                  {item.endDate
                                    ? detectAndParseDate(item.endDate)
                                    : "N/A"}{" "}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </>
              ) : null}
              {skills && skills.length > 0 ? (
                <>
                  <SectionTitle title="Skills" />
                  <div className="flex h-44 items-center justify-between">
                    <Radar
                      data={{
                        labels: labelsChartset,
                        datasets: chartDataset,
                      }}
                      options={options}
                    />
                  </div>
                </>
              ) : null}

              {educations && educations.length > 0 ? (
                <>
                  <SectionTitle title="Education" />
                  <div className="flex flex-col flex-wrap  gap-2 px-8 py-1">
                    {educations
                      ?.filter(
                        (obj) =>
                          (obj.name !== "" && obj.name !== undefined) ||
                          (obj.schoolName !== "" &&
                            obj.schoolName !== undefined),
                      )
                      .map((item, index) => (
                        <div
                          key={index}
                          className="flex flex-1 justify-between  rounded-lg border border-[#E0E0E0] bg-[#F7F9FB] px-4 py-2 shadow-education-card "
                        >
                          <div className="font-primary text-base font-light text-[#4A5164]">
                            {item?.name}
                          </div>
                          <div className="font-primary text-base font-light text-[#4A5164]">
                            {item?.schoolName}
                          </div>
                        </div>
                      ))}
                  </div>
                </>
              ) : null}
              {languages && languages.length > 0 ? (
                <>
                  <SectionTitle title="Languages" />
                  {languages?.map((item) => (
                    <div className="flex flex-col gap-2 px-8" key={item.id}>
                      <div className="flex flex-wrap items-center gap-2">
                        <div className="w-20 flex-shrink-0">
                          <div className="rounded border bg-blue-50 px-2 py-[6px] text-center font-primary text-sm font-medium text-blue-b-primary">
                            {item?.name}
                          </div>
                        </div>
                        <StarRating
                          maxRating={4}
                          defaultRating={item?.level}
                          showDefaultAmount={false}
                          disableInteraction={true}
                        />
                      </div>
                    </div>
                  ))}
                </>
              ) : null}
            </div>
          ) : (
            <div className="flex flex-col items-center justify-center gap-4 px-4 text-center font-primary text-3xl font-normal text-black-g-100">
              <MenuIcon height={50} width={50} />
              This section is going to be filled with every step, keep it up
            </div>
          )}
        </div>

        <div className="m-4 flex flex-col gap-4">
          <div className="font-primary text-sm font-normal text-[#4A5164]">
            Your profile is at {Math.round((currentStep * 100) / totalSteps)}%
          </div>
          <LinearProgress percentage={(currentStep * 100) / totalSteps} />
          <div className="text-center font-primary text-sm font-normal text-[#6F6F6F]">
            you will be able to download your CV once its complete, you’re
            almost there !
          </div>
          <div
            className="btn-blue-b-primary flex flex-1 items-center justify-center rounded-md py-2 font-primary text-base font-medium"
            // disabled={Object.keys(errors).length != 0 || isPending}
            // isLoading={isPending}
            // loadingLoader={<Loader size={1.2} color="#ffffff" />}

            // onClick={() => {
            //   if (currentStep !== 7) {
            //     handleNext();
            //   } else {
            //     handleSubmit(saveData)();
            //   }
            // }}
          >
            Download your CV
          </div>
        </div>
      </div>
    </div>
  );
}

SliderInfo.propTypes = {
  currentStep: PropTypes.number.isRequired,
  totalSteps: PropTypes.number.isRequired,
  formObj: PropTypes.shape({
    watch: PropTypes.func.isRequired,
    getValues: PropTypes.func.isRequired,
  }),
  user: PropTypes.shape(),
};

export default SliderInfo;
