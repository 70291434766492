/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-unescaped-entities */
import Input from "../../shared/inputs/Input";
import Divider from "../../shared/Divider";
import ControlledDropdown from "../../shared/dropdown/ControlledDropdown";
import TextArea from "../../shared/inputs/TextArea";
import {
  EmploymentTypeArray,
  contractTypeArray,
  leaveNoticeArray,
  qualificationArray,
} from "../../utils/constants";
import { useGetIndustries } from "../../hooks/useGetIndustries";
import { useFormContext } from "react-hook-form";
import CustomDatePick from "../../shared/datepicker/CustomDatePick";
import { PropTypes } from "prop-types";
import { useGetCurrencies } from "../../hooks/useGetCurrencies";
import { Slider } from "@mui/material";
import { useEffect, useState } from "react";

function CandidatePersonalInformationStep({ hideClearSection }) {
  const { data: industries } = useGetIndustries();
  const { data: currencies } = useGetCurrencies();

  const { getValues, control, resetField, setValue, watch } = useFormContext();
  const [salaryValue, setSalaryValue] = useState(null);
  const [tjmValue, setTjmValue] = useState(null);
  const { salaryRange, tjmRange } = watch();

  useEffect(() => {
    if (salaryRange) {
      setSalaryValue([salaryRange[0], salaryRange[1]]);
    }
  }, [salaryRange]);

  useEffect(() => {
    if (tjmRange) {
      setTjmValue([tjmRange[0], tjmRange[1]]);
    }
  }, [tjmRange]);

  return (
    <div className="flex flex-1 flex-col">
      <div className="py-4 font-primary text-[25px] font-black leading-10 text-black-g-primary">
        1. Personal Information
      </div>
      <div className="mt-4 h-full">
        <div className="flex  flex-col">
          <div className="flex w-full ">
            <div
              className={`flex ${!hideClearSection ? "w-full" : "w-7/12"} flex-col overflow-hidden`}
            >
              <div className="flex  items-center">
                <div className="font-primary text-xl font-bold text-black-g-500">
                  Basic details
                </div>
                <Divider className="ml-4 flex-1" />
              </div>
              <div className="flex w-full flex-col gap-4 py-4">
                <div className="flex items-center gap-2">
                  <Input
                    column={true}
                    label={"Your first name"}
                    type="text"
                    id={"firstName"}
                    placeholder="Enter your first name"
                    controllerName={"firstName"}
                    control={control}
                    mainDivClassname={"flex-1"}
                    containerClassName="border-black-g-50"
                    containerBaseClassName="border rounded-md"
                    labelClassName="font-primary font-semibold cursor-pointer text-sm text-[#060F26]"
                    fieldClassName="input-transparent text-sm font-primary font-normal bg-white "
                    resetField={resetField}
                    /* disabled={!hideClearSection} */
                    gap={0}
                  />
                  <Input
                    column={true}
                    label={"Your last name"}
                    type="text"
                    id={"lastName"}
                    placeholder="Enter your last name"
                    controllerName={"lastName"}
                    control={control}
                    mainDivClassname={"flex-1"}
                    containerClassName="border-black-g-50"
                    containerBaseClassName="border rounded-md"
                    labelClassName="font-primary font-semibold cursor-pointer text-sm text-[#060F26]"
                    fieldClassName="input-transparent text-sm font-primary font-normal bg-white "
                    resetField={resetField}
                    /* disabled={!hideClearSection} */
                    gap={0}
                  />
                </div>

                <div className="flex items-center gap-2">
                  {/* <CustomDatePick
                    controllerName={"birthDate"}
                    labelName={"Birthdate"}
                    control={control}
                    className="flex-1"
                  /> */}

                  <ControlledDropdown
                    controllerName="educationLevel"
                    id={"educationLevel"}
                    control={control}
                    items={qualificationArray}
                    defaultValue={getValues("educationLevel")}
                    labelName={"Education"}
                    className=" rounded-[4px] border border-black-g-50 px-2 py-1 font-primary text-sm font-medium"
                  />
                </div>

                <div className="flex items-center gap-2">
                  <Input
                    column={true}
                    label={"Job Title"}
                    type="text"
                    id={"jobTitle"}
                    placeholder="Enter job title"
                    controllerName={"jobTitle"}
                    control={control}
                    mainDivClassname={"flex-1"}
                    containerClassName="border-black-g-50"
                    containerBaseClassName="border rounded-md"
                    labelClassName="font-primary font-semibold cursor-pointer text-sm text-[#060F26]"
                    fieldClassName="input-transparent text-sm font-primary font-normal bg-white "
                    resetField={resetField}
                    gap={0}
                  />
                  <ControlledDropdown
                    controllerName="industryId"
                    id={"industryId"}
                    control={control}
                    items={industries}
                    defaultValue={getValues("industryId")}
                    labelName={"Job Sought"}
                    className=" rounded-[4px] border border-black-g-50   px-2 py-1 font-primary text-sm font-medium"
                  />
                </div>
                <div className="flex items-center gap-2">
                  <Input
                    column={true}
                    label={"Experience"}
                    type="number"
                    id={"yearsOfExperience"}
                    placeholder="Enter number of years of Experience"
                    controllerName={"yearsOfExperience"}
                    control={control}
                    mainDivClassname={"flex-1"}
                    containerClassName="border-black-g-50"
                    containerBaseClassName="border rounded-md"
                    labelClassName="font-primary font-semibold cursor-pointer text-sm text-[#060F26]"
                    fieldClassName="input-transparent text-sm font-primary font-normal bg-white "
                    resetField={resetField}
                    gap={0}
                  />
                  <ControlledDropdown
                    controllerName="disponibility"
                    id={"disponibility"}
                    control={control}
                    items={leaveNoticeArray}
                    defaultValue={getValues("disponibility")}
                    labelName={"Disponibility"}
                    className=" rounded-[4px] border border-black-g-50   px-2 py-1 font-primary text-sm font-medium"
                  />
                </div>
                <div className="flex items-center gap-2">
                  <ControlledDropdown
                    controllerName="preferredEmploymentType"
                    id={"preferredEmploymentType"}
                    control={control}
                    items={EmploymentTypeArray}
                    defaultValue={getValues("preferredEmploymentType")}
                    labelName={"Status"}
                    className=" rounded-[4px] border border-black-g-50   px-2 py-1 font-primary text-sm font-medium"
                  />
                  {/* <ControlledDropdown
                    controllerName="preferredContractType"
                    id={"preferredContractType"}
                    control={control}
                    items={contractTypeArray}
                    defaultValue={getValues("preferredContractType")}
                    labelName={"TJM"}
                    className=" rounded-[4px] border border-black-g-50   px-2 py-1 font-primary text-sm font-medium"
                  /> */}

                  <div className="flex flex-1 flex-col flex-wrap gap-3">
                    <div className="py-1.5 font-primary text-sm font-semibold">
                      TJM
                    </div>
                    <div className="px-3">
                      <Slider
                        value={tjmValue}
                        onChange={(event, newValue) => {
                          setValue("tjmRange", newValue);
                        }}
                        valueLabelDisplay="auto"
                        sx={{
                          color: "#0054FF",
                          height: "8px",
                          padding: "0px",
                        }}
                        step={100}
                        min={0}
                        max={9000}
                      />
                    </div>
                  </div>
                </div>

                <div className="flex items-center gap-2">
                  <div className="flex flex-1 flex-col flex-wrap gap-3">
                    <div className="py-1.5 font-primary text-sm font-semibold">
                      Salary Expectation
                    </div>
                    <div className="px-3">
                      <Slider
                        value={salaryValue}
                        onChange={(event, newValue) => {
                          setValue("salaryRange", newValue);
                        }}
                        valueLabelDisplay="auto"
                        sx={{
                          color: "#0054FF",
                          height: "8px",
                          padding: "0px",
                        }}
                        step={100}
                        min={0}
                        max={9000}
                      />
                    </div>
                  </div>

                  <ControlledDropdown
                    controllerName="rate.currencyId"
                    control={control}
                    id="currency"
                    items={currencies}
                    defaultValue={getValues("rate.currencyId")}
                    labelName={"Currency"}
                    className="rounded-[4px] border border-black-g-50 px-2 py-1 font-primary text-sm font-medium"
                  />
                </div>

                <TextArea
                  label="About Info"
                  id="about"
                  rows={5}
                  placeholder="Describe yourself ..."
                  autoComplete="off"
                  controllerName="description"
                  control={control}
                  containerClassName="bg-white border"
                  resetField={resetField}
                />
              </div>
            </div>

            {/* {!hideClearSection ? (
              <div className="ml-4 flex w-5/12 flex-col">
                <div className="flex items-center">
                  <div className="font-primary text-base font-light text-blue-b-primary">
                    Clear section
                  </div>
                  <Divider className="ml-4 flex-1" />
                </div>
                <div className="my-4 font-primary text-sm font-light text-[#4A5164]">
                  Here, you'll highlight basic details such as your educational
                  background, work experience
                </div>
              </div>
            ) : null} */}
          </div>
        </div>
      </div>
    </div>
  );
}

CandidatePersonalInformationStep.propTypes = {
  hideClearSection: PropTypes.bool,
};
CandidatePersonalInformationStep.defaultProps = {
  hideClearSection: false,
};

export default CandidatePersonalInformationStep;
